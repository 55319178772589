<template>
  <div
    id="star-page"
    class="star-page"
  >
    <div class="star-info">
      <img
        class="avatar"
        :src="detail && detail.avatar ? detail.avatar:require('@/assets/images/icon-avatar@2x.png')"
      >
      <div class="common-info">
        <div>
          <span class="nickname">{{ detail && detail.nickname ? detail.nickname : '-' }}</span>
          <img
            class="icon-gender"
            src="@/assets/images/icon-male@2x.png"
            v-if="detail && detail.gender === 1"
          >
          <img
            class="icon-gender"
            src="@/assets/images/icon-female@2x.png"
            v-else-if="detail && detail.gender === 2"
          >
        </div>
        <div class="brief">
          <span>ID: {{ detail ? (detail.short_id ? detail.short_id : detail.id) : '-' }}</span>
          <span class="mcn">MCN：
            <label class="mcn-name">
              {{ detail && detail.mcn && detail.mcn.name ? detail.mcn.name : '-' }}
            </label>
          </span>
        </div>
      </div>
      <van-button
        class="btn-changed"
        type="primary"
        @click="changeBounds"
        v-if="session"
      >
        切换
      </van-button>
    </div>
    <div
      class="tag-info"
      v-if="detail && detail.tags"
    >
      <div class="tag-list">
        <div
          class="tag-item"
          v-for="tag in detail.tags"
          :key="tag"
        >
          <span class="tag-name">{{ tag }}</span>
        </div>
      </div>
    </div>
    <div class="price-info">
      <div class="price-item">
        <div class="type">
          <span>1-20s视频</span>
        </div>
        <div class="price">
          <span>
            <label class="symbol">¥</label>{{
              detail && detail.video_1_20s_price ? $toYuan(detail.video_1_20s_price) : '-'
            }}
          </span>
        </div>
      </div>
      <div class="price-item">
        <div class="type">
          <span>21-60s视频</span>
        </div>
        <div class="price">
          <span>
            <label class="symbol">¥</label>{{
              detail && detail.video_21_60s_price ? $toYuan(detail.video_21_60s_price) : '-'
            }}
          </span>
        </div>
      </div>
      <div class="price-item">
        <div class="type">
          <span>60s以上视频</span>
        </div>
        <div class="price">
          <span>
            <label class="symbol">¥</label>{{
              detail && detail.video_60s_above_price ? $toYuan(detail.video_60s_above_price) : '-'
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="common-stat-info">
      <div class="common-stat-left">
        <div class="title">
          <span>粉丝数</span>
        </div>
        <div class="value">
          <span>{{ getFollowsNum() }}</span>
        </div>
        <div class="title second-line">
          <span>星图指数</span>
        </div>
        <div class="value">
          <span>{{ detail && detail.xingtu_score ? detail.xingtu_score : '-' }}</span>
        </div>
      </div>
      <div class="common-stat-right">
        <div class="common-stat-group">
          <div class="common-stat-item">
            <div class="title">
              <span>预期CPM(1-20s)</span>
            </div>
            <div class="value">
              <span>{{ getCpm() }}</span>
            </div>
          </div>
          <div class="common-stat-item">
            <div class="title">
              <span>预期CPE(1-20s)</span>
            </div>
            <div class="value">
              <span>{{ getCpe() }}</span>
            </div>
          </div>
        </div>
        <div class="common-stat-group second-line">
          <div class="common-stat-item">
            <div class="title">
              <span>完播率(近30天)</span>
            </div>
            <div class="value">
              <span>{{ getPlayOverRate() }}</span>
            </div>
          </div>
          <div class="common-stat-item">
            <div class="title">
              <span>互动率(近30天)</span>
            </div>
            <div class="value">
              <span>{{ getInteractRate() }}</span>
            </div>
          </div>
        </div>
        <div class="connect top" />
        <div class="connect bottom" />
      </div>
    </div>
    <div class="tab_container">
      <tab
        :items="tabItems"
        @change="handleTabChanged"
      />
    </div>
    <div class="overview">
      <follower-overview
        :data="detail"
        v-if="tabActiveIndex === 0"
      />
      <spread-overview
        :data="detail"
        v-else-if="tabActiveIndex === 1"
      />
      <business-info
        :data="detail"
        v-else-if="tabActiveIndex === 2"
      />
      <bound-sessions
        :sessions="sessions"
        v-else-if="tabActiveIndex === 3"
      />
    </div>
    <div
      class="bottom-placeholder"
    />
    <div
      class="bottom"
      :style="'width:'+bottomWidth+'px;'"
    >
      <van-button
        class="btn-add-bind"
        :class="!hasPermission ? 'full':''"
        @click="toAddBind"
        v-if="session"
      >
        新增绑定
      </van-button>
      <van-button
        class="btn-back"
        :class="!hasPermission ? 'full':''"
        @click="back"
        v-else
      >
        返回上一级
      </van-button>
      <van-button
        class="btn-edit-business-info"
        @click="goToEditBusinessInfo"
        v-if="hasPermission"
      >
        录入权益
      </van-button>
    </div>

    <van-action-sheet
      v-model:show="showAddBindType"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="handleSelectAddBind"
    />

    <div
      class="top"
      :style="'width:'+bottomWidth+'px;'"
    >
      <img
        class="top-image"
        src="@/assets/images/icon-top@2x.png"
        @click="scrollToTop()"
        v-if="showTopControl"
      >
    </div>
  </div>
</template>

<script>
import BoundSessions from '@/views/establish/BoundSessions';
import BusinessInfo from './BusinessInfo';
import FollowerOverview from './FollowerOverview';
import SpreadOverview from './SpreadOverview';
import Tab from '@/components/Tab';
import { mapActions } from 'vuex';
import { Toast } from 'vant';

export default {
  name: 'Star',
  components: {
    BoundSessions,
    BusinessInfo,
    FollowerOverview,
    SpreadOverview,
    Tab,
  },
  props: {
    session: {
      type: Object,
      default() {
        return null;
      },
    },
    data: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      bottomWidth: 0,
      tabItems: [
        '粉丝情况',
        '传播表现',
        '达人权益',
        '客户/群聊'
      ],
      tabActiveIndex: 0,
      detail: undefined,
      sessions: [],
      hasPermission: false,
      showAddBindType: false,
      actions: [
        { name: '绑定达人' },
        { name: '绑定MCN机构' },
      ],
      currentUser: undefined,
      showTopControl: false,
    };
  },
  watch: {
    data(newValue, oldValue) {
      if (newValue.id !== oldValue.id) {
        this.toGetTiktokStarDetail();
      }
    }
  },
  created() {
    this.toGetTiktokStarDetail();
    this.toGetTiktokStarBound();
    this.toGetLoginUser();
    this.$nextTick(function () {
      const page_el = document.getElementById('star-page');
      if (page_el) {
        this.bottomWidth = page_el.offsetWidth;
        this.topWidth = page_el.offsetWidth;
      }
    });

    // const mutationObserver = new MutationObserver(function (mutations) {
    //   if (mutations && mutations.length > 0 && mutations[0].type === 'attributes') {
    //     this.showTopControl = document.body.scrollTop > 100;
    //   }
    // });
    //
    // mutationObserver.observe(document.body, {
    //   attributes: true,
    //   attributeFilter: ['class', 'style'],
    // });
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    back() {
      this.$router.back();
    },
    handleScroll() {
      this.$nextTick(function () {
        this.showTopControl = document.documentElement.scrollTop > 100;
      });
    },
    toAddBind() {
      this.showAddBindType = true;
    },
    handleSelectAddBind(action, index) {
      if (!this.session || !this.session.id) {
        Toast.fail({
          message: '会话数据异常,请刷新后重试',
        });
        return;
      }
      if (index === 0) {
        this.$router.push({
          name: 'BindingSessionStar',
          params: {
            id: this.session.id,
          },
        });
      } else if (index === 1) {
        this.$router.push({
          name: 'BindingSessionMcn',
          params: {
            id: this.session.id,
          },
        });
      }
    },
    getFollowsNum() {
      if (!this.detail || !this.detail.followers) {
        return '-';
      }

      return this.$convertUnit(this.detail.followers) + 'w';
    },
    getPlayOverRate() {
      if (!this.detail || !this.detail.xingtu_play_over_rate_last_30_days) {
        return '-%';
      }

      return this.$convertUnit(this.detail.xingtu_play_over_rate_last_30_days, 100) + '%';
    },
    getInteractRate() {
      if (!this.detail || !this.detail.xingtu_interact_rate_last_30_days) {
        return '-%';
      }

      return this.$convertUnit(this.detail.xingtu_interact_rate_last_30_days, 100) + '%';
    },
    getCpm(index = 0) {
      const types = ['1_20', '21_60', '60_above'];
      const type = types[index];
      const key = 'xingtu_expect_cpm_' + type;
      if (!this.detail || !this.detail[key]) {
        return '-';
      }
      return '¥ ' + this.$toYuan(this.detail[key], 2);
    },
    getCpe(index = 0) {
      const types = ['1_20', '21_60', '60_above'];
      const type = types[index];
      const key = 'xingtu_expect_cpe_' + type;
      if (!this.detail || !this.detail[key]) {
        return '-';
      }
      return '¥ ' + this.$toYuan(this.detail[key], 2);
    },
    handleTabChanged(item, index) {
      this.tabActiveIndex = index;
    },
    async toGetTiktokStarDetail() {
      if (!this.data || !this.data.id) {
        return;
      }
      this.detail = this.data;

      const res = await this.getTiktokStarDetail({ id: this.data.id });
      if (!res) {
        return;
      }
      this.detail = res;
    },
    async toGetTiktokStarBound() {
      if (!this.data || !this.data.id) {
        return;
      }
      this.detail = this.data;

      const res = await this.getTiktokStarBound({ starId: this.data.id });
      if (!res) {
        return;
      }
      this.sessions = res;
    },
    changeBounds() {
      this.$emit('changeBounds');
    },
    goToEditBusinessInfo() {
      this.$router.push({
        name: 'EditBusinessInfo',
        params: {
          id: this.data.id,
        },
      });
    },
    async toGetLoginUser() {
      const res = await this.getLoginUser();
      if (!res) {
        return;
      }
      this.user = res;
      this.hasPermission = this.user.permission && this.user.permission.star_edit;
    },
    ...mapActions({
      getTiktokStarDetail:
        'star/getTiktokStarDetail',
      getTiktokStarBound:
        'session/getTiktokStarBound',
      getLoginUser:
        'user/getLoginUser',
    }),
    scrollToTop() {
      const animationScrollToTop = () => {
        let sTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (sTop > 0) {
          window.requestAnimationFrame(animationScrollToTop);
          window.scrollTo(0, sTop - sTop / 8);
        }
      };

      let sTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (sTop > 0) {
        window.requestAnimationFrame(animationScrollToTop);
        window.scrollTo(0, sTop - sTop / 8);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.star-page {
  background: #fff;

  .star-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;

    .avatar {
      flex-shrink: 0;
      width: 108px;
      height: 108px;
      border-radius: 60px;
    }

    .common-info {
      width: 100%;
      margin-left: 30px;
      overflow: hidden;

      .nickname {
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        line-height: 32px;
      }

      .icon-gender {
        width: 26px;
        height: 26px;
        margin-left: 10px;
      }

      .brief {
        font-size: 24px;
        font-weight: 400;
        color: #999;
        line-height: 32px;
        margin-top: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .mcn {
        margin-left: 20px;

        .mcn-name {
          color: #0095FF;
        }
      }
    }

    .btn-changed {
      flex-shrink: 0;
      background: #0095FF;
      border-radius: 27px;
      width: 120px;
      height: 54px;
      font-size: 26px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 26px;
    }
  }

  .tag-info {
    display: flex;
    padding: 0 30px 30px 30px;

    .tag-list {
      display: flex;
      align-items: center;
      column-count: auto;
      column-gap: 0;
      flex-wrap: wrap;
      flex-direction: row;
      align-content: flex-start;

      .tag-item {
        display: flex;
        align-items: center;
        height: 54px;
        background: #EBEBEB;
        border-radius: 27px;
        font-size: 26px;
        font-weight: 400;
        color: #666;
        line-height: 28px;
        padding: 0 18px;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }

  .price-info {
    display: flex;
    margin: 0 30px 10px 30px;
    background: rgba(255, 0, 108, 0.02);
    border-radius: 8px;
    border: 1px solid rgba(255, 0, 108, 0.1);
    align-items: center;
    justify-content: space-evenly;
    padding: 40px 0;

    .price-item {
      text-align: center;

      .type {
        font-size: 24px;
        font-weight: 400;
        color: #333;
        line-height: 24px;
      }

      .symbol {
        font-size: 28px;
        font-weight: 400;
        color: #F5346E;
        line-height: 28px;
      }

      .price {
        font-size: 36px;
        font-weight: 600;
        color: #F5346E;
        line-height: 36px;
        margin-top: 14px;
      }
    }
  }

  .common-stat-info {
    display: flex;
    position: relative;
    padding: 0 30px 40px 30px;

    .common-stat-left {
      background: #F2F9FF;
      border-radius: 8px;
      border: 1px solid #D4EDFF;
      padding: 40px 20px 40px 30px;
    }

    .common-stat-right {
      position: relative;
      flex: 1;
      border-radius: 8px;
      border: 1px solid #EBEBEB;
      margin-left: 10px;
      padding-left: 50px;
      padding-top: 40px;

      .connect {
        position: absolute;
        width: 30px;
        height: 16px;
        border-radius: 8px;
        border: 1px solid #D4EDFF;
        background: #fff;
        left: -20px;
        z-index: 99;
      }

      .top {
        top: 90px
      }

      .bottom {
        bottom: 50px
      }

      .common-stat-group {
        display: flex;

        .common-stat-item {
          width: 50%;
        }
      }
    }

    .title {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }

    .value {
      font-size: 36px;
      font-weight: 600;
      color: #0095FF;
      line-height: 36px;
      margin-top: 14px;
    }

    .second-line {
      margin-top: 42px;
    }

    .second-column {
      margin-left: 60px;
    }
  }

  .tab_container {
    padding-left: 30px;
    border-bottom: 1px solid #E0E0E0;
  }

  .bottom-placeholder {
    height: 160px;
  }

  .bottom {
    display: flex;
    align-items: center;
    background: #fff;
    height: 160px;
    border-top: 1px solid #E6E6E6;
    position: fixed;
    bottom: 0;

    .btn-back,
    .btn-add-bind {
      width: 36%;
      background: #FFFFFF;
      border-radius: 40px;
      border: 1px solid #0095FF;
      font-size: 30px;
      font-weight: 600;
      color: #0095FF;
      line-height: 30px;
      margin-left: 30px;

      &:active {
        opacity: 0.8;
      }
    }

    .full {
      width: 100%;
      margin-right: 30px;
    }

    .btn-edit-business-info {
      width: 64%;
      background: #0095FF;
      border-radius: 40px;
      font-size: 30px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 10px;
      margin-right: 30px;

      &:active {
        opacity: 0.8;
      }
    }
  }
  .top {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 168px;
  }
  .top-image {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
}
</style>