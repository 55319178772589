<template>
  <div class="spread-overview-page">
    <section-header
      :icon="require('@/assets/images/m-icon-title@2x.png')"
      title="星图指数"
      brief="(行业均值)"
    />
    <div class="overview-container">
      <div class="item-group">
        <div class="item">
          <div class="title">
            星图指数
          </div>
          <div class="value">
            {{ !data || data.xingtu_score === null ? '-' : data.xingtu_score }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            传播指数
          </div>
          <div class="value">
            {{ getSpreadScore() }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            成长指数
          </div>
          <div class="value">
            {{ getGrowthScore() }}
          </div>
        </div>
      </div>
      <div class="item-group">
        <div class="item">
          <div class="title">
            性价比指数
          </div>
          <div class="value">
            {{ getCostPerformanceScore() }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            合作指数
          </div>
          <div class="value">
            {{ getCooperateScore() }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            种草指数
          </div>
          <div class="value">
            {{ getShoppingScore() }}
          </div>
        </div>
      </div>
    </div>
    <section-header
      class="video-overview-header"
      :icon="require('@/assets/images/m-icon-title@2x.png')"
      title="视频表现"
    />
    <div class="overview-container">
      <div class="item-group">
        <div class="item">
          <div class="title">
            均播放量(万)
          </div>
          <div class="value">
            {{ data && data.xingtu_avg_play !== null ? $convertUnit(data.xingtu_avg_play) : '-' }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            预期播放量(万)
          </div>
          <div class="value">
            {{ data && data.expected_play !== null ? $convertUnit(data.expected_play) : '-' }}
          </div>
        </div>
      </div>
    </div>

    <section-header
      class="video-overview-header"
      title="1-20s视频"
    />
    <div class="overview-container">
      <div class="item-group">
        <div class="item">
          <div class="title">
            预期CPM
          </div>
          <div class="value">
            {{ getCpm() }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            预期CPE
          </div>
          <div class="value">
            {{ getCpe() }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            播放成本
          </div>
          <div class="value">
            {{ getPlayAvgPrice() }}
          </div>
        </div>
      </div>
    </div>
    <section-header
      class="video-overview-header"
      title="21-60s视频"
    />
    <div class="overview-container">
      <div class="item-group">
        <div class="item">
          <div class="title">
            预期CPM
          </div>
          <div class="value">
            {{ getCpm(1) }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            预期CPE
          </div>
          <div class="value">
            {{ getCpe(1) }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            播放成本
          </div>
          <div class="value">
            {{ getPlayAvgPrice(1) }}
          </div>
        </div>
      </div>
    </div>
    <section-header
      class="video-overview-header"
      title="60s以上视频"
    />
    <div class="overview-container">
      <div class="item-group">
        <div class="item">
          <div class="title">
            预期CPM
          </div>
          <div class="value">
            {{ getCpm(2) }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            预期CPE
          </div>
          <div class="value">
            {{ getCpe(2) }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            播放成本
          </div>
          <div class="value">
            {{ getPlayAvgPrice(2) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader';


export default {
  name: 'SpreadOverview',
  components: {
    SectionHeader,
  },
  props: {
    data: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  methods: {
    getSpreadScore() {
      if (this.data.spread_score === null) {
        return '-';
      }
      if (this.data.median_spread_score === null) {
        return this.data.spread_score;
      }

      return this.data.spread_score + ' (' + this.data.median_spread_score + ')';
    },
    getCooperateScore() {
      if (this.data.cooperate_score === null) {
        return '-';
      }
      if (this.data.median_cooperate_score === null) {
        return this.data.cooperate_score;
      }

      return this.data.cooperate_score + ' (' + this.data.median_cooperate_score + ')';
    },
    getShoppingScore() {
      if (this.data.shopping_score === null) {
        return '-';
      }
      if (this.data.median_shopping_score === null) {
        return this.data.shopping_score;
      }

      return this.data.shopping_score + ' (' + this.data.median_shopping_score + ')';
    },
    getGrowthScore() {
      if (this.data.growth_score === null) {
        return '-';
      }
      if (this.data.median_growth_score === null) {
        return this.data.growth_score;
      }

      return this.data.growth_score + ' (' + this.data.median_growth_score + ')';
    },
    getCostPerformanceScore() {
      if (this.data.cost_performance_score === null) {
        return '-';
      }
      if (this.data.median_cost_performance_score === null) {
        return this.data.cost_performance_score;
      }

      return this.data.cost_performance_score + ' (' + this.data.median_cost_performance_score + ')';
    },
    getCpm(index = 0) {
      const keys = [
        'xingtu_expect_cpm_1_20',
        'xingtu_expect_cpm_21_60',
        'xingtu_expect_cpm_60_above',
      ];
      const key = keys[index];
      const value = this.data[key];
      if (value === null) {
        return '-';
      }
      return '¥' + this.$toYuan(value, 1);
    },
    getCpe(index = 0) {
      const keys = [
        'xingtu_expect_cpe_1_20',
        'xingtu_expect_cpe_21_60',
        'xingtu_expect_cpe_60_above',
      ];
      const key = keys[index];
      const value = this.data[key];
      if (value === null) {
        return '-';
      }
      return '¥' + this.$toYuan(value, 1);
    },
    getPlayAvgPrice(index = 0) {
      if (!this.data.xingtu_avg_play) {
        return '-';
      }
      const keys = [
        'video_1_20s_price',
        'video_21_60s_price',
        'video_60s_above_price',
      ];
      const key = keys[index];
      const value = this.data[key];
      if (!value) {
        return '-';
      }
      let price = value / this.data.xingtu_avg_play;
      if (price < 1) {
        price = 1;
      }
      return '¥' + this.$toYuan(price, 2);
    }
  },
};
</script>

<style lang="less" scoped>
.spread-overview-page {
  background: #fff;
  padding: 40px 30px;

  .overview-container {
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    padding: 40px;
    margin-top: 20px;
  }

  .video-overview-header {
    margin-top: 40px;
  }

  .item-group {
    display: flex;

    &:nth-child(n+2) {
      margin-top: 50px;
    }

    .item-header {
      font-size: 32px;
      font-weight: 600;
      color: #333;
      line-height: 32px;
      margin-top: 14px;
    }

    .item {
      min-width: 220px;

      .title {
        font-size: 24px;
        font-weight: 400;
        color: #666;
        line-height: 24px;
      }

      .value {
        font-size: 32px;
        font-weight: 600;
        color: #333;
        line-height: 32px;
        margin-top: 14px;
      }
    }
  }
}
</style>