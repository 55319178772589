<template>
  <div class="business-info-page">
    <div class="container">
      <div class="item">
        <div class="title">
          <span>权益项</span>
        </div>
        <div class="value">
          <span>权益内容</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>返点比例</span>
        </div>
        <div class="value">
          <span>{{ getCommissionRate() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>视频保留时长</span>
        </div>
        <div class="value">
          <span>{{ this.data.video_keep_duration || '-' }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>视频授权及费用</span>
        </div>
        <div class="value">
          <span>{{ getAuthorizeVideo() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>二次剪辑及费用</span>
        </div>
        <div class="value">
          <span>{{ getSecondaryCreation() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>授权投放品牌<br>DOU+</span>
        </div>
        <div class="value">
          <span>{{ this.data.authorized_marketing_brand || '-' }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>配合评论置顶</span>
        </div>
        <div class="value">
          <span>{{ getSupportPinComment() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>免费分发平台</span>
        </div>
        <div class="value">
          <span>{{ getHasFreeDistributionPlatform() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>带话题</span>
        </div>
        <div class="value">
          <span>{{ getSupportTopic() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>使用贴纸/BGM</span>
        </div>
        <div class="value">
          <span>{{ getSupportUsePicOrBgm() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>挂link费用</span>
        </div>
        <div class="value">
          <span>{{ getLinkFee() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>购物车费用</span>
        </div>
        <div class="value">
          <span>{{ getShoppingCartFee() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>购物车佣金</span>
        </div>
        <div class="value">
          <span>{{ getShoppingCartCommissionRate() }}</span>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <span>备注</span>
        </div>
        <div class="value">
          <span>{{ data.remark || '-' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessInfo',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  methods: {
    getCommissionRate() {
      if (this.data.commission_rate === null) {
        return '-';
      }
      return this.$convertUnit(this.data.commission_rate, 100, 0) + '%';
    },
    getAuthorizeVideo() {
      if (this.data.authorize_video === null) {
        return '暂无';
      } else if (this.data.authorize_video) {
        return '授权, ¥' + this.$toYuan(this.data.authorize_video_fee);
      } else {
        return '不授权';
      }
    },
    getSecondaryCreation() {
      if (this.data.support_secondary_creation === null) {
        return '暂无';
      } else if (this.data.support_secondary_creation) {
        return '支持, ¥' + this.$toYuan(this.data.secondary_creation_fee);
      } else {
        return '不支持';
      }
    },
    getSupportPinComment() {
      if (this.data.support_pin_comment === null) {
        return '暂无';
      } else if (this.data.support_pin_comment) {
        return '支持';
      } else {
        return '不支持';
      }
    },
    getHasFreeDistributionPlatform() {
      if (this.data.has_free_distribution_platform) {
        return '有';
      } else {
        return '无';
      }
    },
    getSupportTopic() {
      if (this.data.support_topic === null) {
        return '暂无';
      } else if (this.data.support_topic) {
        return '支持';
      } else {
        return '不支持';
      }
    },
    getSupportUsePicOrBgm() {
      if (this.data.support_use_pic_or_bgm === null) {
        return '暂无';
      } else if (this.data.support_use_pic_or_bgm) {
        return '支持';
      } else {
        return '不支持';
      }
    },
    getLinkFee() {
      if (this.data.support_link === null) {
        return '暂无';
      } else if (this.data.support_link) {
        return '支持, ¥' + this.$toYuan(this.data.link_fee);
      } else {
        return '不支持';
      }
    },
    getShoppingCartFee() {
      if (this.data.support_shopping_cart === null) {
        return '暂无';
      } else if (this.data.support_shopping_cart) {
        return '支持, ¥' + this.$toYuan(this.data.shopping_cart_fee);
      } else {
        return '不支持';
      }
    },
    getShoppingCartCommissionRate() {
      if (this.data.shopping_cart_commission_rate == null || this.data.shopping_cart_commission_rate === '') {
        return '-';
      }
      return this.$convertUnit(this.data.shopping_cart_commission_rate, 100, 0) + '%';
    },
  }
};
</script>

<style lang="less" scoped>
.business-info-page {
  padding: 40px 30px;
  background: #fff;

  .container {
    border-radius: 8px;
    border: 1px solid #E6E6E6;

    .item {
      display: flex;
      font-size: 28px;
      font-weight: 400;
      color: #333;
      line-height: 34px;

      &:first-child {
        background: #F7F7F7;
        padding: 36px 30px;
        border-bottom: 1px solid #E6E6E6;
      }

      &:nth-child(n+2) {
        margin: 0 20px;
        padding: 36px 10px;
        border-bottom: 1px solid #E0E0E0;
      }

      &:last-child {
        border: none;
      }

      .title {
        width: 270px;
        flex-shrink: 0;
      }

      .value {

      }
    }
  }
}
</style>