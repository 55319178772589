<template>
  <div class="tab-pair">
    <div
      class="tab-item"
      :class="activeIndex === 0 ? 'active':''"
      @click="change(firstTitle, 0)"
    >
      <span>{{ firstTitle }}</span>
    </div>
    <div
      class="tab-item"
      :class="activeIndex === 1 ? 'active':''"
      @click="change(secondTitle, 1)"
    >
      <span>{{ secondTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabPair',
  props: {
    firstTitle: {
      type: String,
      default: ''
    },
    secondTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    change(item, index) {
      this.activeIndex = index;
      this.$emit('change', item, index);
    }
  }
};
</script>

<style lang="less" scoped>
.tab-pair {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .tab-item {
    background: #F7F7F7;
    padding: 13px 30px;
    font-size: 28px;
    font-weight: 400;
    color: #333;
    line-height: 28px;
    cursor: pointer;

    &:nth-child(1) {
      border-radius: 27px 0 0 27px;
    }

    &:nth-child(2) {
      border-radius: 0 27px 27px 0;
    }

  }

  .active {
    background: #0095FF;
    color: #FFF;
  }
}
</style>