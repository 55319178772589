<template>
  <div class="follower-overview-page">
    <tab-pair
      first-title="粉丝"
      second-title="观众"
      @change="handleTabChange"
    />

    <section-header
      :icon="require('@/assets/images/m-icon-title@2x.png')"
      title="粉丝占比"
    />
    <div class="gender-distribution">
      <div class="gender-item">
        <div class="title">
          <span>女性占比</span>
        </div>
        <div class="value">
          <span>{{ getGenderRate('female') }}</span>
        </div>
      </div>
      <div class="gender-item">
        <div class="title">
          <span>男性占比</span>
        </div>
        <div class="value">
          <span>{{ getGenderRate('male') }}</span>
        </div>
      </div>
    </div>

    <section-header
      class="age-distribution-header"
      :icon="require('@/assets/images/m-icon-title@2x.png')"
      title="年龄分布"
    />

    <div
      class="follower-distribution echarts-bar"
      ref="followerDistribution"
      v-if="showFollowerDistribution"
    />
    <div
      class="empty"
      v-else
    >
      <div>
        <div class="image-box">
          <img
            class="image"
            src="@/assets/images/pic-noresult@2x.png"
          >
        </div>
        <div class="text">
          <span>暂无结果</span>
        </div>
      </div>
    </div>

    <section-header
      class="region-distribution-header"
      :icon="require('@/assets/images/m-icon-title@2x.png')"
      title="地区分布TOP3"
    />
    <div class="region-distribution">
      <div
        class="province-distribution echarts-bar"
        ref="provinceDistribution"
        v-if="showProvinceDistribution"
      />
      <div
        class="empty province-distribution"
        v-else
      >
        <div>
          <div class="image-box">
            <img
              class="image"
              src="@/assets/images/pic-noresult@2x.png"
            >
          </div>
          <div class="text">
            <span>暂无结果</span>
          </div>
        </div>
      </div>

      <div
        class="city-distribution echarts-bar"
        ref="cityDistribution"
        v-if="showCityDistribution"
      />
      <div
        class="empty city-distribution"
        v-else
      >
        <div>
          <div class="image-box">
            <img
              class="image"
              src="@/assets/images/pic-noresult@2x.png"
            >
          </div>
          <div class="text">
            <span>暂无结果</span>
          </div>
        </div>
      </div>
    </div>

    <section-header
      class="device-distribution-header"
      :icon="require('@/assets/images/m-icon-title@2x.png')"
      title="设备分布"
    />
    <div class="device-distribution">
      <div class="item">
        <div class="title">
          iPhone
        </div>
        <div class="value">
          {{ getDeviceDistribution()[0] }}
        </div>
      </div>
      <div class="item">
        <div class="title">
          其他
        </div>
        <div class="value">
          {{ getDeviceDistribution()[1] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader';
import TabPair from '@/components/TabPair';
import * as echarts from 'echarts';

export default {
  name: 'FollowerOverview',
  components: {
    SectionHeader,
    TabPair,
  },
  props: {
    data: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      tabActiveIndex: 0,
      showFollowerDistribution: false,
      followerDistributionOption: {
        grid: {
          top: '16%',
          bottom: '24%',
          left: '-2%',
          right: '-2%',
        },
        xAxis: {
          type: 'category',
          axisTick: false,
          axisLine: {
            show: false,
          },
          data: [],
        },
        yAxis: [
          {
            type: 'value',
            axisTick: false,
            axisLabel: false,
            axisLine: false,
            splitLine: false,
          },
        ],
        series: [
          {
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'top',
              formatter: '{c}%',
              align: 'center',
              color: '#333',
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#4DB5FF' },
                { offset: 1, color: '#CFEBFF' },
              ]),
              borderRadius: [50, 50, 0, 0],
            },
            barWidth: 20,
          },
        ],
      },
      showProvinceDistribution: false,
      provinceDistributionOption: {
        grid: {
          top: '16%',
          bottom: '24%',
          left: '-2%',
          right: '-2%',
        },
        xAxis: {
          type: 'category',
          axisTick: false,
          axisLine: {
            show: false,
          },
          data: [],
        },
        yAxis: [
          {
            type: 'value',
            axisTick: false,
            axisLabel: false,
            axisLine: false,
            splitLine: false,
          },
        ],
        series: [
          {
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'top',
              formatter: '{c}%',
              align: 'center',
              color: '#333',
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#4DB5FF' },
                { offset: 1, color: '#CFEBFF' },
              ]),
              borderRadius: [50, 50, 0, 0],
            },
            barWidth: 20,
          },
        ],
      },
      showCityDistribution: false,
      cityDistributionOption: {
        grid: {
          top: '16%',
          bottom: '24%',
          left: '-2%',
          right: '-2%',
        },
        xAxis: {
          type: 'category',
          axisTick: false,
          axisLine: {
            show: false,
          },
          data: [],
        },
        yAxis: [
          {
            type: 'value',
            axisTick: false,
            axisLabel: false,
            axisLine: false,
            splitLine: false,
          },
        ],
        series: [
          {
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'top',
              formatter: '{c}%',
              align: 'center',
              color: '#333',
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#FF84A9' },
                { offset: 1, color: '#FFD2DF' },
              ]),
              borderRadius: [50, 50, 0, 0],
            },
            barWidth: 20,
          },
        ],
      },
    };
  },
  watch: {
    data(newValue, oldValue) {
      this.setupDistribution();
    }
  },
  created() {
    this.$nextTick(() => {
      this.setupDistribution();
    });
  },
  methods: {
    handleTabChange(item, index) {
      this.tabActiveIndex = index;
      this.setupDistribution();
    },
    getDeviceDistribution() {
      let type = 'follower';
      if (this.tabActiveIndex === 1) {
        type = 'watcher';
      }
      const value = this.data[type + '_device_distribution'];
      if (!value || value.length === 0) {
        return ['-%', '-%'];
      }
      const list = value.filter((x) => x.key === 'iPhone');
      let rate = 0;
      if (list && list.length > 0) {
        rate = list[0].rate;
        if (rate > 10000) {
          rate = 10000;
        }
      }
      const other_rate = 10000 - rate;
      const iphoneRate = (rate / 100).toFixed(2) + '%';
      const otherRate = (other_rate / 100).toFixed(2) + '%';
      return [iphoneRate, otherRate];
    },
    setupDistribution() {
      this.followerDistributionOption.xAxis.data = ['<18', '18~23', '24~30', '31~40', '41~50', '50+'];
      this.cityDistributionOption.xAxis.data = [];
      this.cityDistributionOption.series[0].data = [];
      this.provinceDistributionOption.xAxis.data = [];
      this.provinceDistributionOption.series[0].data = [];

      if (this.data) {
        let type = 'followers';
        if (this.tabActiveIndex === 1) {
          type = 'watchers';
        }
        const age_keys = ['other', '18_23', '24_30', '31_40', '41_50', '50_above'];
        let total = 0;
        const values = age_keys.map((x) => {
          const val = this.data[type + '_age_' + x];
          if (!val) {
            return 0;
          }
          total += val;
          return val;
        });

        this.followerDistributionOption.series[0].data = values.map((x) => {
          if (!total) {
            return 0;
          }
          return parseFloat((x * 100 / total).toFixed(1));
        });

        this.showFollowerDistribution = total > 0;

        type = 'follower';
        if (this.tabActiveIndex === 1) {
          type = 'watcher';
        }

        const provinceDistribution = this.data[type + '_province_distribution'];
        if (provinceDistribution && provinceDistribution.length > 0) {
          for (let i = 0; i < provinceDistribution.length && i < 3; i++) {
            const item = provinceDistribution[i];
            this.provinceDistributionOption.xAxis.data.push(item.key);
            this.provinceDistributionOption.series[0].data.push(this.$convertUnit(item.rate, 100));
          }
          this.showProvinceDistribution = true;
        } else {
          this.showProvinceDistribution = false;
        }

        const cityDistribution = this.data[type + '_city_distribution'];
        if (cityDistribution && cityDistribution.length > 0) {
          for (let i = 0; i < cityDistribution.length && i < 3; i++) {
            const item = cityDistribution[i];
            this.cityDistributionOption.xAxis.data.push(item.key);
            this.cityDistributionOption.series[0].data.push(this.$convertUnit(item.rate, 100));
          }
          this.showCityDistribution = true;
        } else {
          this.showCityDistribution = false;
        }
      } else {
        this.followerDistributionOption.series[0].data = [];
        this.showFollowerDistribution = false;
      }

      this.$nextTick(() => {
        if(this.$refs.followerDistribution){
          const followerDistributionChart = echarts.init(
              this.$refs.followerDistribution
          );
          followerDistributionChart.setOption(this.followerDistributionOption);
        }
      });

      this.$nextTick(() => {
        if(this.$refs.provinceDistribution){
          const provinceDistributionChart = echarts.init(
              this.$refs.provinceDistribution
          );
          provinceDistributionChart.setOption(this.provinceDistributionOption);
        }
      });

      this.$nextTick(() => {
        if(this.$refs.cityDistribution){
          const cityDistributionChart = echarts.init(
              this.$refs.cityDistribution
          );
          cityDistributionChart.setOption(this.cityDistributionOption);
        }
      });
    },
    getGenderRate(gender) {
      let type = 'followers';
      if (this.tabActiveIndex === 1) {
        type = 'watchers';
      }
      const rate = this.data[gender + '_' + type + '_rate'];
      if (!rate) {
        return '-%';
      }
      return this.$convertUnit(rate, 100) + '%';
    }
  }
};
</script>

<style lang="less" scoped>
.follower-overview-page {
  background: #fff;
  padding: 40px 30px;

  .echarts-bar {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    margin-top: 20px;
  }

  .gender-distribution {
    margin-top: 20px;
    display: flex;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    align-items: center;
    padding: 40px;

    .gender-item {
      min-width: 220px;

      .title {
        font-size: 24px;
        font-weight: 400;
        color: #666;
        line-height: 24px;
      }

      .value {
        font-size: 36px;
        font-weight: 600;
        color: #333;
        line-height: 32px;
        margin-top: 14px;
      }
    }
  }

  .age-distribution-header {
    margin-top: 50px;
  }

  .follower-distribution {

  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    margin-top: 20px;

    .image-box {
      display: flex;
      justify-content: center;

      .image {
        width: 185px;
        height: 165px;
        flex-shrink: 0;
      }
    }


    .text {
      font-size: 30px;
      font-weight: 400;
      color: #333;
      line-height: 44px;
      margin-top: 30px;
      text-align: center;
    }
  }


  .region-distribution-header {
    margin-top: 50px;
  }

  .region-distribution {
    display: flex;
  }

  .province-distribution {
    margin-right: 5px;
  }

  .city-distribution {
    margin-left: 5px;
  }

  .device-distribution-header {
    margin-top: 50px;
  }

  .device-distribution {
    display: flex;
    border-radius: 8px;
    padding: 40px;
    border: 1px solid #EBEBEB;
    margin-top: 20px;

    .item {
      min-width: 220px;

      .title {
        font-size: 24px;
        font-weight: 400;
        color: #666;
        line-height: 24px;
      }

      .value {
        font-size: 32px;
        font-weight: 600;
        color: #333;
        line-height: 32px;
        margin-top: 14px;
      }
    }
  }
}
</style>